import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, image: metaImage, title, pageTitle, pathname }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        keywords
                        siteUrl
                    }
                }
            }`
    );

    const metaDescription = description || site.siteMetadata.description;
    const image = metaImage ? metaImage : null;
    const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null;

    const hashtag = "#bondedworld";
    const facebookAppId = "987471025050482";

    return (
        <Helmet
            htmlAttributes={{ lang }}
            title={ pageTitle ? pageTitle : title }
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            link={ canonical ? [
                    {
                        rel: "canonical",
                        href: canonical
                    }
                ] : []
            }
            meta={ [
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: "image",
                    content: image
                },
                {
                    name: "keywords",
                    content: site.siteMetadata.keywords.join(","),
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:hashtag`,
                    content: hashtag
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:creator`,
                    content: "BondedWorld",
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                }
            ]
            .concat(
                metaImage
                    ?
                [
                    {
                        property: "og:image",
                        content: image,
                    },
                    {
                        name: "twitter:card",
                        content: "summary_large_image",
                    }
                ]
                    :
                [
                    {
                        name: "twitter:card",
                        content: "summary",
                    }
                ]
            )
            .concat(meta)} />
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
    }),
    pathname: PropTypes.string
};

export default SEO;
