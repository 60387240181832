import React, { Component } from 'react';
import './Guides.css';

import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { Link } from 'gatsby';
import AdjustableImage from '../elements/AdjustableImage';
import TopIcon from '../elements/TopIcon';
import RenderActivity from '../elements/render/RenderActivity';

import PlaceMetadata from '../../metadata/PlaceMetadata';

class HighlightGuide extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);

        this.topRef = React.createRef();
        this.descriptionRef = React.createRef();
        this.howToGetRef = React.createRef();
        this.whatToDoRef = React.createRef();
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    closeNav() {
        this.setState({ isNavOpen: false });
    }

    render() {

        return (
            <React.Fragment>
                <PlaceMetadata title={ this.props.highlight.name } description={ this.props.highlight.brief } image={ this.props.highlight.image }
                    pathname={ this.props.pathname } />
                <TopIcon />
                <div id="page-top" ref={ this.topRef } className='header-topic'>
                    <AdjustableImage image={ this.props.highlight.image } thumb={ this.props.highlight.thumb } name={ this.props.highlight.name } />
                    <div className="header-text">
                        <p>{ this.props.highlight.country }</p>
                        <h1 className="header-title">{ this.props.highlight.name }</h1>
                        <p className="header-caption">{ this.props.highlight.caption }</p>
                    </div>
                </div>

                <Navbar light sticky="top" className="bg-light border" expand="sm">
                    <div className="container">
                        <NavbarBrand className="d-block d-sm-none" href="" onClick={() => {
                                this.topRef.current.scrollIntoView();
                                this.closeNav(); }}>
                            { this.props.highlight.name }
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.descriptionRef.current.scrollIntoView();
                                            this.closeNav(); }}>Description</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.howToGetRef.current.scrollIntoView();
                                            this.closeNav(); }}>How To Get Here?</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={() => {
                                            this.whatToDoRef.current.scrollIntoView();
                                            this.closeNav(); }}>What To Do?</NavLink>
                                </NavItem>
                            </Nav>
                            <Nav className="ms-auto" navbar>
                                <NavItem>
                                    <NavLink tag={ Link } to={ this.props.urlBack }>{ this.props.highlight.country }</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </div>
                </Navbar>

                <div className="container">
                    <div className="city-header">
                        <h1>{ this.props.highlight.name } Travel Guide</h1>
                    </div>

                    <div className="city-text">
                        <div className="col-12 col-lg-10 ms-auto me-auto" >
                            <div id="description" ref={ this.descriptionRef } >
                                { this.props.highlight.description }
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-10 ms-auto me-auto">
                                <h1 id="description" ref={ this.howToGetRef }>How To Get Here?</h1>
                                { this.props.highlight.howToGet }
                            </div>
                        </div>

                        <div className="city-topic">
                            <div className="col-12 col-lg-10 ms-auto me-auto">
                                <h1 id="activities" ref={ this.whatToDoRef }>What To Do?</h1>
                                { this.props.highlight.whatToDo.description }
                                <RenderActivity activities={ this.props.highlight.whatToDo.activities } />
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default HighlightGuide;
