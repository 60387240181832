import React from 'react';
import { Figure } from 'react-bootstrap';

export const BanGioc = {
    "name": "Ban Gioc Waterfall",
    "link": "ban-gioc-waterfall",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50380520257_be56d1eba5_t.jpg",
    "image": "https://live.staticflickr.com/65535/50380520257_ddabe54ddf_o.jpg",
    "caption": "The Melodies of Falling Water",

    "brief": "Located on the northern part of Vietnam, sitting at the border with China, the Ban Gioc Waterfall is the biggest and most recognizable waterfall in the whole country, and you will find photos of it on many postals and pictures. Measuring about 300 meters wide and standing at a maximum height of 70 meters, this behemoth of a waterfall is a natural sight to behold.",

    "description": <p>
        Located on the northern part of Vietnam, sitting at the border with China, the <b>Ban Gioc Waterfall</b> is the biggest and most recognizable waterfall in the whole country, and you will find photos of it on many postals and pictures.
        Measuring about 300 meters wide and standing at a maximum height of 70 meters, this behemoth of a waterfall is a natural sight to behold.
        <br/><br/>
        Despite the breathtaking scenery, this place is still somewhat <b>off</b> the major tourist track, and it will not be as much crowded as other places in Vietnam. In fact, if you <b>avoid</b> visiting during weekend and holidays, you will have much of the waterfall for yourself.
        The best time to visit this wonder is during the months of <b>September to October</b>, it is when the flux of water gives the best scenery.
        <br/><br/>
        The ticket for entering the waterfalls costs about 2$, and can be purchase at the kiosk nearby.
        </p>,

    "howToGet": <p>
        The <b>Ban Gioc Waterfalls</b> are located at the border between Vietnam and China, in fact, half of the waterfall is part of one country and the other half is part of the other country.
        The closest <i>"major"</i> city from the waterfalls is <b>Cao Bang</b>. You can reach it by catching a <b>bus</b> from <b>Hanoi</b>, the journey takes around 8 hours. There are also buses connecting Cao Bang to <b>Ha Long Bay</b> and <b>Ha Giang</b>.
        <br/><br/>
        Once you arrive at <b>Cao Bang</b>, there is a designated <b>bus</b> that takes you directly to the waterfalls, the journey takes around 2 hours, and a ticket for a roundtrip costs 4$.
        If going by bus <b>pay attention</b> to the last bus returning from the waterfalls to Cao Bang (around <b>6 pm</b>), since if you miss it you will have to call a taxi, and it can be a little expensive.
        <br/><br/>
        Another way to get to the waterfalls is by <b>motorbike</b>. There are many options to rent a motorbike for a day in Cao Bang, or ideally you would already arrive in town on a motorbike after driving through some of the great scenery of Northern Vietnam.
        If you come to the waterfalls by motorbike you will have <b>to pay 0.5$</b> to park the bike.
        <br/><br/>
        For those that don't fancy much planning, there are <b>organized tours</b> departing from Hanoi especially designed to visit the Ban Gioc Waterfalls.
        One example is <a href="http://www.mrlinhadventure.com/en/vietnam-tours/multi-day-extended-tours/302-adventure-to-ban-gioc-waterfall--ba-be-lake-3-days-2-nights.aspx" target="_blank" rel="noopener noreferrer">this</a> organized tour, that will take you through the <b>Ban Gioc Waterfalls</b> and the <b>Ba Be National Park</b>.
        <br/><br/>
        Because the waterfalls are part of the Chinese border, sometimes the police station at the waterfall requires foreigners to <b>purchase a border permit</b>, which costs about 1$. <b>Don't forget</b> to bring your passport with you, in case this happens you will need it.
        </p>,

    "whatToDo": {
        "description": <div>
            <p>
            Once at the waterfall, you will probably want to get up <b>close</b> to the stream of water. There are many <b>boat owners</b> on bamboo rafts here and you can get a ride up close to the waterfall for around 3$.
            There are rafts of two colors, the blue ones are from Vietnam, and the green ones are Chinese.
            <br/><br/>
            </p>
            <Figure.Image className="col-12 col-lg-10 offset-lg-1" src="https://live.staticflickr.com/65535/50514555876_47285433bf_o.jpg" alt="Ban Gioc Waterfall" fluid />
            <Figure.Caption className="text-center">Bamboo raft getting close to the waterfall.</Figure.Caption>
            <p>
            <br/>
            Although <i>technically illegal</i>, there is a path on the side of the waterfall that takes you to an above platform with a great view for the waterfall, this platform is on the <b>Chinese side</b>, whence the <i>illegal</i> part.
            The path is slippery and sometimes there is a guard stopping people for progressing further.
            Another good <b>viewpoint</b> of the waterfalls and the surrounding karsts can be found a little further out. Head to the <b>Truc Lam Phat Tich Pagoda</b>, if you climb it until the top you will have a great view.
            </p>
            </div>,

        "activities": [
            {
                "name": "Nguom Ngao Cave",
                "description": <p>
                    About 4 km from the Ban Gioc Waterfalls is a famous cave known as the <b>Tiger Cave</b>.
                    Created by an underground river, it stretches for several kilometers, and visitors can walk through a section of 1 km.
                    Entrance is about 2$.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50514719367_fcae764d0f_o.jpg"
            },
            {
                "name": "Ba Be Lake",
                "description": <p>
                    Although the lake is not in any instance close to the waterfalls, it is normal for tours to the <b>Ban Gioc Watefall</b> to also stop at the <b>Ba Be National Park</b>.
                    This lake is the biggest natural lake on Vietnam, and you can do many activities such as canoeing, kayaking and more. The national park gets its name from the lake, and offers a great landscape full of waterfalls, rivers and caves.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50514555836_d1e57cfd5d_o.jpg"
            }
        ]
    }
}
