import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEOComponent';

function PlaceMetadata(props) {
    const currentUrl = "https://bondedworld.com" + props.pathname;

    const title = props.title + " Travel Guide";
    const cardTitle = "BondedWorld - " + props.title + " Travel Guide";
    const image = props.image;

    const description = props.description;

    return (
        <SEO title={ cardTitle } pageTitle={ title } description={ description } image={ image } pathname={ props.pathname } />
    );

    /*
    const faqQuestions = () => {
        let jsonObj = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": []
        }

        if (props.nrDays !== undefined && props.nrDays !== null ) {
            jsonObj.mainEntity.push({
                "@type": "Question",
                "name": `How many days in ${props.name}?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": props.nrDays
                }
            })
        }

        if (props.whenVisit !== undefined && props.whenVisit !== null ) {
            jsonObj.mainEntity.push({
                "@type": "Question",
                "name": `When to visit ${props.name}?`,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": props.whenVisit
                }
            })
        }

        return (
            <React.Fragment>
            <script type="application/ld+json">
                { jsonObj }
            </script>
            <meta name="someteste" content="teste" />
            </React.Fragment>
        )
    }
    */

/*
    return (
        <Helmet>
            <title>{ cardTitle }</title>
            <meta property="title" content={ title } />
            <meta name="description" content={ description } />
            <meta property="image" content={ image } />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ cardTitle } />
            <meta property="og:hashtag" content={ hashtag } />
            <meta property="og:image" content={ image } />
            <meta content="image/*" property="og:image:type" />
            <meta property="og:url" content={ currentUrl } />
            <meta property="og:site_name" content={ cardTitle } />
            <meta property="og:description" content={ description } />
            <meta property="fb:app_id" content={ facebookAppId } />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content={ currentUrl } />
            <meta name="twitter:title" content={ cardTitle } />
            <meta name="twitter:description" content={ description } />
            <meta name="twitter:image" content={ image } />
        </Helmet>
    )
    */
}

export default PlaceMetadata;
