import React from 'react';

function RenderActivity (props) {

    return props.activities.map((activity, key) => {
        return (
            <div style={{ marginTop: '40px', marginBottom: '15px' }} key={ key }>
                <h4>{ activity.name }</h4>
                <img src={ activity.image } alt={activity.name } width="100%" loading="lazy" />
                <div style={{ marginTop: '20px' }}>
                    { activity.description }
                </div>
            </div>
        );
    });
}

export default RenderActivity;
