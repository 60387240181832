import React from 'react';
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeEurope } from '@fortawesome/free-solid-svg-icons';

import DelayedLoading from './DelayedLoading';

function TopIcon(props) {
    return (
        <DelayedLoading delayTime={ 100 }>
            <div style={{ position: 'absolute', zIndex: '10' }}>
                <Link to="/" style={{ textDecoration: 'inherit', color: 'inherit' }}>
                    <div className="header-mainpage-div non-selectable" style={{ backgroundColor: 'rgba(0.0, 0.0, 0.0, 0.24)', padding: '12px 15px 6px 15px', borderRadius: '0px 0px 10px 0px' }}>
                        <h1 className="header-mainpage-title d-none d-sm-block" style={{ fontSize: '1.6em' }}>Bonded <FontAwesomeIcon icon={faGlobeEurope} /> World</h1>
                        <h1 className="header-mainpage-title d-block d-sm-none" style={{ fontSize: '1em' }}>Bonded <FontAwesomeIcon icon={faGlobeEurope} /> World</h1>
                    </div>
                </Link>
            </div>
        </DelayedLoading>
    );
}

export default TopIcon;
