import React from 'react';
import HighlightGuide from '../../../../components/guides/HighlightComponent';
import Footer from '../../../../components/FooterComponent';

import { BanGioc } from '../../../../guides/countries/vietnam/ban-gioc';

function BanGiocPage(props) {
    return (
        <div>
            <HighlightGuide highlight={ BanGioc } pathname={ props.location.pathname } urlBack="/travel-guides/asia/vietnam" />
            <Footer />
        </div>
    );
}

export default BanGiocPage;
