import React from 'react';
import PropTypes from 'prop-types';

function DelayedLoading(props) {
    const [ isLoading, setIsLoading ] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, props.delayTime)
    }, [props.delayTime])

    if (isLoading && !props.isReady) {
        return (<div></div>);
    } else {
        return props.children;
    }
}

DelayedLoading.defaultProps = {
    isReady: false
};

DelayedLoading.propTypes = {
    delayTime: PropTypes.number.isRequired,
    isReady: PropTypes.bool.isRequired
};

export default DelayedLoading;